<template>
  <div>
    <van-sticky>
      <van-nav-bar right-text="分享" @click-right="onClickShare">
        <template #title>
          <span style="font-weight: 600; color: #0af"
            >GOLANG ROADMAP·Go岗位</span
          >
        </template>
        <template #left>
          <van-image
            radius="18px"
            height="40px"
            width="40px"
            @click="txClick"
            src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/toux.jpg"
          />
        </template>
      </van-nav-bar>

      <div v-if="showBanner">
        <van-image
          radius="4px"
          src="https://image-1302243118.cos.ap-beijing.myqcloud.com/img/7a04f6688975fb60a4b8d00fc3ea7174.gif"
          alt=""
        />
      </div>

      <!-- 城市切换 -->
      <van-tabs @click="onClick">
        <van-tab v-for="(item, index) in category" :key="index">
          <template #title>
            <div class="category-item">
              <span>
                {{ item.value }}
              </span>
              <div>
                {{ item.label }}
              </div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </van-sticky>
    <van-notice-bar
      v-if="showBanner"
      left-icon="volume-o"
      text="定期汇总更新热门Go岗位信息，推荐Gopher赢内推奖金。"
    />

    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <!-- Go岗位列表 -->
      <van-grid style="padding-top: 5px" :gutter="5" :column-num="1">
        <van-grid-item v-for="(item, index) in list" :key="index">
          <van-cell @click="handleClick(item)">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <van-row>
                <van-col span="18" style="font-weight: bold">
                  <van-tag plain type="danger" v-if="item.urgentStatus === '1'"
                    >急</van-tag
                  >
                  {{ item.post }}
                </van-col>
                <van-col span="6" style="text-align: right; color: brown">{{
                  item.salary
                }}</van-col>
              </van-row>

              <van-row>
                <van-col span="14">
                  {{ item.company }}
                </van-col>
                <!-- <van-col
                  v-if="item.bonus"
                  span="10"
                  style="text-align: right; color: red"
                  >推荐入职得：{{ item.bonus }}</van-col
                > -->
              </van-row>
            </template>

            <template #label>
              <van-row type="flex" gutter="2">
                <van-col span="3"
                  ><van-tag size="medium" type="warning">{{
                    item.postCity
                  }}</van-tag></van-col
                >
                <van-col span="3"
                  ><van-tag size="medium" type="primary">{{
                    item.education
                  }}</van-tag></van-col
                >
                <!-- <van-col span="3"> <van-tag size="medium"
                           type="primary">{{item.experience}}</van-tag></van-col> -->
                <!-- style="overflow: hidden; text-align: right;" -->
                <van-col span="18">
                  <div
                    style="overflow: hidden; height: 18px; text-align: right"
                  >
                    {{ item.address }}
                  </div>
                </van-col>
              </van-row>
            </template>
          </van-cell>
        </van-grid-item>
      </van-grid>
    </van-list>

    <van-popup
      round
      position="bottom"
      :style="{ height: '92%' }"
      v-model="detialshow"
    >
      <post-detail get-container="#app" :postDetail="postDetail" />
    </van-popup>

    <van-share-sheet
      v-model="showShare"
      title="立即分享"
      :options="shareOptions"
      @select="onSelect"
    />
  </div>
</template>

<script>
import {
  Sticky,
  ImagePreview,
  Dialog,
  ShareSheet,
  NoticeBar,
  Tabs,
  Tab,
  Popup,
  NavBar,
  Image as VanImage,
  Button,
  RadioGroup,
  Radio,
  Form,
  Field,
  Toast,
  Search,
  DropdownMenu,
  DropdownItem,
  List,
  Cell,
  CellGroup,
  Tag,
  Icon,
  Col,
  Row,
  Rate,
  Switch,
  Grid,
  GridItem,
} from "vant";
import PostDetail from "../../components/PostDetail.vue";
const axios = require("axios");

export default {
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Dialog.Component.name]: Dialog.Component,
    [Sticky.name]: Sticky,
    [ShareSheet.name]: ShareSheet,
    PostDetail,
    [NoticeBar.name]: NoticeBar,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [VanImage.name]: VanImage,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Form.name]: Form,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Search.name]: Search,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Rate.name]: Rate,
    [Switch.name]: Switch,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
  },

  data() {
    return {
      showShare: false,
      shareOptions: [
        { name: "微信", icon: "wechat" },
        {
          name: "分享海报",
          icon: "https://img01.yzcdn.cn/vant/custom-icon-light.png",
        },
      ],
      // Go岗位分类
      category: [
        { label: "全部", value: "🔵" },
        { label: "远程", value: "🌐" },
        { label: "北京", value: "📚" },
        { label: "上海", value: "📦" },
        { label: "广州", value: "🗳️" },
        { label: "深圳", value: "🕹️" },
        { label: "杭州", value: "📰" },
        { label: "西安", value: "🚀" },
        { label: "南京", value: "🕸️" },
        { label: "武汉", value: "🔗" },
        { label: "成都", value: "⚔️" },
        { label: "苏州", value: "🧰" },
        { label: "郑州", value: "🎭" },
        { label: "重庆", value: "☁️" },
        { label: "长沙", value: "🏦" },
        { label: "厦门", value: "🏄" },
        { label: "天津", value: "🍱" },
        { label: "福州", value: "🍚" },
        { label: "合肥", value: "🀄" },
        { label: "东莞", value: "⛲" },
        { label: "济南", value: "🛸" },
      ],
      showBanner: true,
      //题目列表
      list: [],

      //查询条件form
      queryform: {
        city: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      finished: false,
      detialshow: false,
      postDetail: {
        address: "雁塔区华为西安研究所锦业路127号",
        bonus: "2000",
        company: "华为",
        companyCity: "西安",
        createTime: "2022-01-08",
        education: "本科",
        experience: "校招",
        id: 31,
        memo: "",
        post: "开发",
        postCity: "重庆",
        pushName: "MR.Tan",
        salary: "16K*14薪",
        urgentStatus: "0",
        detail: "",
      },
    };
  },
  mounted() {
    // 显示弹出框
    this.showBanner = true;
    // 5 秒后自动隐藏
    setTimeout(() => {
      this.showBanner = false;
    }, 5000);
  },
  // created () {
  //   this.search()
  // },
  methods: {
    //点击头像进入服务页
    txClick() {
      this.$router.push("user");      
    },

    //分享
    onSelect(shareOption) {
      if (shareOption.name == "分享海报") {
        var shareImage =
          "https://image-1302243118.cos.ap-beijing.myqcloud.com/share/post_0.jpg";
        const img = new Image();
        img.src = shareImage;
        img.onload = function () {
          console.log("Image loaded successfully.");
          ImagePreview({
            images: [shareImage],
            closeable: true,
          });
          this.showShare = false;
          // Do something if the image is loaded successfully
        };
        img.onerror = function () {
          console.log("Failed to load image.");
          // Do something if the image fails to load
          Dialog({
            message: "暂无海报,请选择微信分享",
            theme: "round-button",
            confirmButtonColor: "#0af",
          });
        };
      } else {
        Dialog({
          message: "Go岗位已复制到剪切板，前往微信分享给朋友吧！",
          theme: "round-button",
          confirmButtonColor: "#0af",
        });
        this.showShare = false;
        let container = this.$refs.container;
        var shareUrl =
          "Go岗位专区" +
          ": https://m.golangroadmap.com/#/post。GOLANG ROADMAP一个专注Go语言学习、求职的社区。";
        this.$copyText(shareUrl, container);
      }
    },

    //查看详情
    handleClick(item) {
      Object.assign(this.postDetail, item);
      this.detialshow = true;
    },

    //导航切换
    onClick(val) {
      switch (val) {
        case 1:
          this.queryform.city = "远程";
          break;
        case 2:
          this.queryform.city = "北京";
          break;
        case 3:
          this.queryform.city = "上海";
          break;
        case 4:
          this.queryform.city = "广州";
          break;
        case 5:
          this.queryform.city = "深圳";
          break;
        case 6:
          this.queryform.city = "杭州";
          break;
        case 7:
          this.queryform.city = "西安";
          break;
        case 8:
          this.queryform.city = "南京";
          break;
        case 9:
          this.queryform.city = "武汉";
          break;
        case 10:
          this.queryform.city = "成都";
          break;
        case 11:
          this.queryform.city = "苏州";
          break;
        case 12:
          this.queryform.city = "郑州";
          break;
        case 13:
          this.queryform.city = "重庆";
          break;
        case 14:
          this.queryform.city = "长沙";
          break;
        case 15:
          this.queryform.city = "厦门";
          break;
        case 16:
          this.queryform.city = "天津";
          break;
        case 17:
          this.queryform.city = "福州";
          break;
        case 18:
          this.queryform.city = "合肥";
          break;
        case 19:
          this.queryform.city = "东莞";
          break;
        case 20:
          this.queryform.city = "济南";
          break;
        default:
          this.queryform.city = "";
      }

      this.search();
    },
    search() {
      this.queryform.pageNo = 1;
      axios
        .post(
          "https://api.golangroadmap.com/gopherclub/m/posts",
          this.queryform
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.list = response.data.data.clubPosts;
            console.log(this.list);
            this.total = response.data.data.count;
            if (this.list.length >= this.total) {
              this.finished = true;
            } else {
              this.finished = false;
            }
          } else {
            Toast(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onLoad() {
      this.queryform.pageNo = this.list.length / 10 + 1;
      axios
        .post(
          "https://api.golangroadmap.com/gopherclub/m/posts",
          this.queryform
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            this.total = response.data.data.count;
            for (var i = 0; i < response.data.data.clubPosts.length; i++) {
              this.list.push(response.data.data.clubPosts[i]);
            }
            //    加载状态结束
            this.loading = false;
            //全部加载完毕
            if (this.list.length >= this.total) {
              this.finished = true;
            }
          } else {
            Toast(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //打开分享
    onClickShare() {
      this.showShare = true;
    },
  },
};
</script>

<style lang="less">
// .van-tabs__line {
//   background-color: #39a9ed;
// }

.my-swipe .van-swipe-item {
  color: #fff;
  width: 300px;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.van-grid-item__content {
  padding: 0;
}

.grid-item {
  display: flex;
  flex-direction: column;
}

.grid-img {
  align-items: center;
  width: 100%;
  height: 130px;
  margin-bottom: 10px;
}

.grid-title {
  padding: 0 7px;
  font-size: 14px;

  height: 40px;
}
</style>
